import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CursoInfo = ({ sesion }) => (
  <section className="pt-4">
    <div className="container">
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Introducción</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{ __html: sesion?.Introduccion }}
            ></div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  </section>
);

export default CursoInfo;
