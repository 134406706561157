import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Concursos = () => {
  const [torneos, setTorneos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const GetTorneos = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}torneo`, {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`,
          },
        });
        setTorneos(response.data);
      } catch (error) {
        console.error("Error al obtener los Torneos:", error);
      } finally {
        setLoading(false);
      }
    };

    GetTorneos();
  }, []);

  return (
    <>
      <Navbar />
      <section
        className="bg-dark align-items-center d-flex"
        style={{
          background:
            "url(/assets/images/stemisp/Concurso.jpeg) no-repeat center",
          backgroundSize: "cover",
          height: "150px", // Ajusta la altura según sea necesario
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-white">Concursos</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-0 pt-md-5">
        <div className="container">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center m-7">
              <div className="text-center">
                <div className="loader"></div>
              </div>
            </div>
          ) : (
            <>
              {torneos.length > 0 ? (
                <div className="row g-4">
                  {torneos.map((torneo, index) => (
                    <div key={index} className="col-md-6 col-lg-4 col-xxl-3">
                      <Link to={`/Detalles/Concurso/${torneo.Slug}`}>
                        <div className="card p-2 shadow h-100">
                          <div className="rounded-top overflow-hidden">
                            <div className="card-overlay-hover">
                              {/* Image */}
                              <img
                                src={`${window.BACKEND_URL}uploads/${torneo.photo}`}
                                alt={torneo?.Titulo}
                                className="card-img"
                              />
                            </div>

                            <div className="card-img-overlay">
                              <div className="card-element-hover d-flex justify-content-end">
                                <a
                                  to={`/RegistroAlumno`}
                                  className="icon-md bg-white rounded-circle"
                                >
                                  <i className="fa-solid fa-user-plus text-danger"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          {/* Card body */}

                          <div className="card-body px-2">
                            {/* Badge and icon */}
                            <div className="d-flex justify-content-between">
                              {/* Rating and info */}
                              <ul className="list-inline hstack gap-2 mb-0">
                                {/* Info */}
                                <li className="list-inline-item d-flex justify-content-center align-items-center">
                                  <div className="icon-md bg-orange bg-opacity-10 text-orange rounded-circle">
                                    <i className="fa-solid fa-file-invoice"></i>{" "}
                                  </div>
                                </li>
                                {/* Rating */}
                                <li className="list-inline-item d-flex justify-content-center align-items-center">
                                  <div className="icon-md bg-warning bg-opacity-15 text-warning rounded-circle">
                                    <i className="fa-solid fa-award fs-5"></i>
                                  </div>
                                </li>
                                <li className="list-inline-item d-flex justify-content-center align-items-center">
                                  <div className="icon-md bg-success  bg-opacity-10 text-success  rounded-circle">
                                    <i className="fa-solid fa-people-group"></i>
                                  </div>
                                </li>
                              </ul>
                              {/* Avatar */}
                              {/*  <div className="avatar avatar-sm">
                                <img
                                  className="avatar-img rounded-circle"
                                  src="/assets/images/logostemis/faviconstemis.png"
                                  alt="Stemis"
                                />
                              </div> */}
                            </div>
                            {/* Divider */}
                            <hr />

                            <h6 className="card-title">{torneo?.Titulo}</h6>
                            {/* Badge and Price */}
                            <div className="d-flex justify-content-between align-items-center mb-0">
                              <div>
                                <a
                                  href="#"
                                  className="badge bg-info bg-opacity-10 text-info me-2"
                                >
                                  <i className="fas fa-circle small fw-bold"></i>{" "}
                                  Stemis
                                </a>
                              </div>

                              <p className="text-success mb-0">
                                + Información{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center">
                  <p>Próximamente concursos...</p>
                </div>
              )}
            </>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Concursos;
