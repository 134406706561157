import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Cardscurso = () => {
  const [cursos, setCursos] = useState([]);
  const [webinars, setWebinar] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para indicar si se está cargando
  const sortedWebinars = webinars
    .sort((a, b) => new Date(b.fechaInicio) - new Date(a.fechaInicio))
    .slice(0, 4);
  const cursosOrdenados = [...cursos].sort(
    (a, b) => new Date(b.created) - new Date(a.created)
  );
  const cursosRecientes = cursosOrdenados.slice(0, 3);

  useEffect(() => {
    const getCursos = async () => {
      try {
        const response = await axios.get(`${window.BACKEND_URL}curso/listado`, {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "Content-Type": "application/json",
            "X-Rol": `${localStorage.getItem("Rol")}`,
          },
        });
        const cursosOrdenados = response.data.sort(
          (a, b) => new Date(a.fecha_in) - new Date(b.fecha_in)
        );
        setCursos(cursosOrdenados);
      } catch (error) {
        console.error("Error al cargar los cursos:", error);
      } finally {
        setLoading(false);
      }
    };
    getCursos();
    const getWebinar = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}Webinarios/Listado/datos`,
          {
            headers: {
              Authorization: `${localStorage.getItem("TokenPublic")}`,
              "Content-Type": "application/json",
              "X-Rol": `${localStorage.getItem("Rol")}`,
            },
          }
        );
        setWebinar(response.data);
      } catch (error) {
        console.error("Error al obtener los Webinarios:", error);
      } finally {
        setLoading(false);
      }
    };
    getWebinar();
  }, []);

  const difi = (nivel) => {
    const pelotas = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= nivel) {
        pelotas.push(
          <span key={i}>
            <i className="fa-solid fa-circle mx-1"></i>
          </span>
        );
      } else {
        pelotas.push(
          <span key={i}>
            <i className="fa-regular fa-circle mx-1"></i>
          </span>
        );
      }
    }
    return pelotas;
  };

  const renderDificultad = (nivel) => {
    let texto;

    switch (nivel) {
      case 1:
        texto = "Principiante";
        break;
      case 2:
        texto = "Básico";
        break;
      case 3:
        texto = "Intermedio";
        break;
      case 4:
        texto = "Avanzado";
        break;
      case 5:
        texto = "Experto";
        break;
      default:
        texto = "Desconocido";
    }

    return texto;
  };

  const formatDateAndTime = (fechaInicio) => {
    const date = new Date(fechaInicio);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = String(hours % 12 || 12).padStart(2, "0");

    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center m-7">
          <div className="text-center">
            <div className="loader"></div>
          </div>
        </div>
      ) : (
        <section className=" bg-light pt-0 pt-lg-5">
          <div className="container">
            {/* Cursos */}
            {cursos.length > 0 && (
              <div key={cursos._id} className="row mb-4">
                <div className="col-12">
                  <h2 className="fs-1" style={{ color: "#009688" }}>
                    <span className="position-relative z-index-9">Cursos</span>
                  </h2>
                  <p className="mb-0">Es tu oportunidad de aprender más</p>
                </div>
              </div>
            )}
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
              {cursosRecientes.map((curso) => (
                <div key={curso.id} className="col">
                  <div
                    className="card shadow-hover overflow-hidden bg-transparent border"
                    style={{ height: "100%" }}
                  >
                    <Link to={`/CursosPage/${curso.slug}`}>
                      <div className="position-relative">
                        <img
                          className="card-img-top"
                          src={`${window.BACKEND_URL}uploads/${curso.pathPortad}`}
                          alt="Card image"
                          style={{ maxHeight: "200px", objectFit: "cover" }}
                        />
                        <div className="bg-overlay bg-dark opacity-4"></div>
                        <div className="card-img-overlay d-flex align-items-start flex-column">
                          <div className="w-100 mt-auto d-inline-flex">
                            <div className="d-flex align-items-center bg-white p-2 rounded-3">
                              <h6 className="mb-0">
                                <a disabled style={{ color: "#009688" }}>
                                  {curso.materia}
                                </a>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-3">
                          <div className="hstack gap-2">
                            <a disabled className="badge text-bg-dark">
                              {curso.dias}
                            </a>
                          </div>
                        </div>
                        <h5 className="card-title">
                          <a disabled>{curso.nombre}</a>
                        </h5>
                        <ul className="list-inline">
                          <li className="list-inline-item ms-2 text-reset">
                            {renderDificultad(curso.dificultad)}
                          </li>
                          <li
                            className="list-inline-item mx-1 small"
                            style={{ color: "#F7C002" }}
                          >
                            {difi(curso.dificultad)}
                          </li>
                        </ul>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h5 className="text-wt mb-0">
                            <i className="fas fa-calendar me-2"></i>
                            {new Date(curso.fecha_in).toLocaleDateString(
                              "es-ES",
                              { day: "numeric", month: "long" }
                            )}
                          </h5>
                          <span className="h6 fw-light mb-0 me-3">
                            <i className="far fa-clock text-danger me-2"></i>
                            {curso.horas}h Académicas
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            {webinars.length > 0 && (
              <div key={webinars.id} className="row mb-4">
                <div className="col-12">
                  <h2 className="fs-1" style={{ color: "#009688" }}>
                    <span className="position-relative z-index-9">
                      Webinars
                    </span>
                  </h2>
                </div>
              </div>
            )}
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
              {sortedWebinars.map((webinar) => (
                <div key={webinar.id} className="col-sm-6 col-lg-4 col-xl-3">
                  <Link to={`/WebinarPage/${webinar.slug}`}>
                    <div className="card shadow h-100">
                      <div className="position-relative">
                        {/* Image */}
                        <img
                          src={`${window.BACKEND_URL}uploads/${webinar.photo}`}
                          className="card-img-top"
                          alt="book image"
                        />
                        {/* Overlay */}
                        <div className="card-img-overlay d-flex z-index-0 p-3">
                          {/* Card overlay Top */}
                          <div className="w-100 mb-auto d-flex justify-content-end">
                            {/* Card format icon */}
                          </div>
                        </div>
                      </div>

                      {/* Card body */}
                      <div className="card-body px-3">
                        {/* Title */}
                        <h5 className="card-title mb-0">
                          <a
                            href="shop-product-detail.html"
                            className="stretched-link"
                          >
                            Webinar-{webinar.materia}
                          </a>
                        </h5>
                      </div>

                      {/* Card footer */}
                      <div className="card-footer pt-0 px-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="fs-6 fw-light mb-0">
                            {formatDateAndTime(webinar.fechaInicio)}
                          </p>
                          {/* Price */}
                          <h5 className="text-success mb-0">Gratuito</h5>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <span className="me-2 fs-5 text-dark">
                              Constancia
                            </span>
                          </div>
                          <h5 className="text-success mb-0">{`S/. ${webinar.precio}`}</h5>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Cardscurso;
