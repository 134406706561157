import React, { useState, useEffect, useRef } from "react";

const Aliados = () => {
  const [count, setCount] = useState(0);
  const targetCount = 1700;
  const duration = 1000; // Duración de la animación en milisegundos
  const [hasStarted, setHasStarted] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Cuando la mitad de la sección es visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasStarted) {
          let start = 0;
          const increment = Math.ceil((targetCount / duration) * 50);
          const timer = setInterval(() => {
            start += increment;
            if (start >= targetCount) {
              clearInterval(timer);
              start = targetCount;
            }
            setCount(start);
          }, 50);
          setHasStarted(true);
          return () => clearInterval(timer);
        }
      });
    }, options);

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [hasStarted, targetCount, duration]);

  return (
    <section id="aliados" ref={sectionRef} className="bg-light pt-md-8">
      <div className="row justify-content-center pb-4">
        <div className="col-12 col-md-10 col-lg-8 text-center">
          <h3 style={{ color: "#464D5B", fontWeight: "bold" }}>
            HEMOS MEJORADO LA EXPERIENCIA EDUCATIVA DE MÁS DE{" "}
            <span style={{ color: "#009688" }}>{count}</span> ESTUDIANTES
          </h3>

          <br />

          <div className="row">
            <div className="col-md-4 col-sm-10 text-center mb-4">
              <img
                src="/assets/images/stemisp/UCSM.png"
                alt="Descripción de la imagen 1"
                className="img-fluid"
                style={{ minWidth: "150px", maxWidth: "80%", height: "auto" }}
              />
            </div>
            <div className="col-md-4 col-sm-10 text-center mb-4">
              <img
                src="/assets/images/stemisp/UTP.png"
                alt="Descripción de la imagen 2"
                className="img-fluid"
                style={{ minWidth: "150px", maxWidth: "80%", height: "auto" }}
              />
            </div>
            <div className=" col-md-4 col-sm-10 text-center ">
              <img
                src="/assets/images/stemisp/UNSA.png"
                alt="Descripción de la imagen 3"
                className="img-fluid"
                style={{ minWidth: "150px", maxWidth: "80%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aliados;
