import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LoginAlumno = () => {
  const [alumno, setAlumno] = useState("");
  const [password, setPassword] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [serverMessage, setServerMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCancel = () => {
    window.history.back();
  };
  const handleLogin = async () => {
    try {
      setLoading(true);
      const postData = {
        email: alumno,
        Contrasena: password,
      };

      const response = await axios.post(
        `${window.BACKEND_URL}LoginAlumno`,
        postData
      );

      if (response.status === 200) {
        sessionStorage.setItem("userName", response.data.name);
        sessionStorage.setItem("email", response.data.email);
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("alumnoId", response.data.alumnoId);
        sessionStorage.setItem("role", response.data.role);

        navigate("/");
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );

      // Si hay un error en la respuesta, mostramos la alerta
      setShowAlert(true);
      // Establecemos el mensaje del servidor en caso de error
      if (error.response) {
        if (error.response.status === 401) {
          setServerMessage(
            "Por favor, confirma tu correo electrónico antes de iniciar sesión."
          );
        } else if (error.response.status === 400) {
          setServerMessage("Credenciales inválidas.");
        } else {
          setServerMessage(
            "Ocurrió un error en el servidor. Inténtalo de nuevo más tarde."
          );
        }
      } else {
        setServerMessage(error.message);
      }
    } finally {
      setLoading(false); // Establecer el estado de carga a false al finalizar la solicitud
    }
  };

  return (
    <>
      <section className="p-0 d-flex align-items-center position-relative overflow-hidden">
        <div className="container-fluid">
          <div className="row">
            {/* Left */}
            <div className="col-12 col-lg-6 d-md-flex align-items-center justify-content-center bg-primary bg-opacity-10 vh-lg-100">
              <img
                src="/assets/images/registro/registro.svg"
                alt="Éxito"
                className="me-3"
              />
            </div>

            {/* Right */}
            <div className="col-12 col-lg-6 m-auto">
              <div className="row my-5">
                <div className="col-sm-10 col-xl-8 m-auto">
                  {/* Title */}
                  <h1 className="fs-2">Bienvenidos a Stemis</h1>
                  <p className="lead mb-4">acceso al aula virtual</p>

                  {/* Form */}
                  <form>
                    {/* Email */}
                    <div className="mb-4">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Correo Electronico *
                      </label>
                      <div className="input-group input-group-lg">
                        <input
                          className="form-control border-0 bg-light rounded-end ps-1"
                          id="email"
                          type="email"
                          name="email"
                          placeholder="Correo electronico"
                          value={alumno}
                          onChange={(e) => setAlumno(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Password */}
                    <div className="mb-4">
                      <label htmlFor="password" className="form-label">
                        Contraseña *
                      </label>
                      <div className="input-group input-group-lg">
                        <input
                          id="password"
                          className="form-control border-0 bg-light rounded-end ps-1"
                          type="password"
                          name="password"
                          placeholder="Escriba su contraseña"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* Alerta */}
                    <div className="d-flex justify-content-center">
                      {showAlert && (
                        <div className="alert alert-danger" role="alert">
                          {serverMessage}{" "}
                          {/* Accede a la propiedad "mensaje" */}
                        </div>
                      )}
                    </div>

                    {/* Botones */}
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn btn-lg btn-secondary me-3"
                        type="button"
                        onClick={handleCancel}
                      >
                        Cancelar
                      </button>
                      <button
                        className="btn btn-lg btn-success text-white"
                        type="button"
                        onClick={handleLogin}
                        disabled={loading} // Deshabilita el botón mientras se carga
                      >
                        {loading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Cargando...</span>
                          </>
                        ) : (
                          <>Ingresar</>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginAlumno;
