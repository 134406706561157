import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import $ from "jquery";
import "select2/dist/js/select2.min.js";
import "select2/dist/css/select2.min.css";
import Swal from "sweetalert2";

const ConcursoIns = () => {
  const { id } = useParams();
  const [torneo, setTorneo] = useState(null);
  const [nombreGrupo, setNombreGrupo] = useState("");
  const [instituciones, setInstituciones] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const institucionesRef = useRef(null);
  const departamentosRef = useRef(null);
  const liderRef = useRef(null);
  const participante1Ref = useRef(null);
  const participante2Ref = useRef(null);
  const participante3Ref = useRef(null);
  const [coach, setCoach] = useState(null);
  const [alumnos, setAlumnos] = useState([]);
  const [selectedAlumnos, setSelectedAlumnos] = useState({
    lider: "",
    participante1: "",
    participante2: "",
    participante3: "",
    coach: "", // Nuevo estado para el Coach
  });
  const [selectedAlumnoDetails, setSelectedAlumnoDetails] = useState(null);
  const [selectedCoachDetails, setSelectedCoachDetails] = useState(null); // Nuevo estado para los detalles del Coach
  const coachRef = useRef(null); // Referencia para el select del Coach
  const [submitting, setSubmitting] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  /*  useEffect(() => {
    if (!alertShown) {
      Swal.fire({
        icon: "warning",
        title: "¡Advertencia!",
        text: "Una vez inscrito, no podrás inscribirte de nuevo.",
        timer: 3000, // 10 segundos
        timerProgressBar: true,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      setAlertShown(true);
    }
  }, [alertShown]); */

  useEffect(() => {
    const GetTorneos = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}torneo/concurso/${id}`,
          {
            headers: {
              Authorization: `${localStorage.getItem("TokenPublic")}`,
              "X-Rol": `${localStorage.getItem("Rol")}`,
            },
          }
        );
        setTorneo(response.data.torneo);
        setInstituciones(response.data?.instituciones?.instituciones || []);
        setDepartamentos(response.data?.departamentos || []);
      } catch (error) {
        console.error("Error al obtener los Torneos:", error);
      } finally {
        setLoading(false);
      }
    };
    GetTorneos();
  }, [id]);

  useEffect(() => {
    const GetAlumnos = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}alumno/inscritos`,
          {
            headers: {
              Authorization: `${localStorage.getItem("TokenPublic")}`,
              "X-Rol": `${localStorage.getItem("Rol")}`,
            },
          }
        );
        setAlumnos(response.data);
      } catch (error) {
        console.error("Error al obtener los alumnos:", error);
      }
    };

    GetAlumnos();
  }, []);

  useEffect(() => {
    if (!loading) {
      // Inicializa Select2 en el select de Instituciones
      if (institucionesRef.current) {
        $(institucionesRef.current)
          .select2({
            placeholder: "Selecciona una institución",
          })
          .on("change", function (e) {
            const institucionId = e.target.value;
            setTorneo((prevTorneo) => ({
              ...prevTorneo,
              institucion_id: institucionId,
            }));
          });
      }

      // Inicializa Select2 en el select de Departamentos
      if (departamentosRef.current) {
        $(departamentosRef.current)
          .select2({
            placeholder: "Selecciona un departamento",
          })
          .on("change", function (e) {
            const departamentoId = e.target.value;
            setTorneo((prevTorneo) => ({
              ...prevTorneo,
              departamento_id: departamentoId,
            }));
          });
      }

      // Inicializa Select2 en los selects de Alumnos
      [
        liderRef,
        participante1Ref,
        participante2Ref,
        participante3Ref,
        coachRef,
      ].forEach((ref, index) => {
        if (ref.current) {
          $(ref.current)
            .select2({
              placeholder:
                index === 0
                  ? "Selecciona un líder"
                  : index === 4
                  ? "Selecciona un coach (opcional)"
                  : `Selecciona el participante ${index}`,
            })
            .on("change", function (e) {
              const alumnoId = e.target.value;
              setSelectedAlumnos((prevSelected) => {
                const newSelected = { ...prevSelected };
                newSelected[Object.keys(newSelected)[index]] = alumnoId;
                return newSelected;
              });
              // Buscar los detalles del alumno seleccionado
              const alumno = alumnos.find((alumno) => alumno._id === alumnoId);
              if (index === 0) {
                setSelectedAlumnoDetails(alumno); // Manejar los detalles del líder
              } else if (index === 4) {
                setSelectedCoachDetails(alumno); // Manejar los detalles del coach si es necesario
              } else {
                setSelectedAlumnoDetails(alumno); // Manejar los detalles del participante
              }
            });
        }
      });
    }
  }, [loading]);

  const filteredAlumnos = (excludeId) => {
    return alumnos.filter(
      (alumno) =>
        !Object.values(selectedAlumnos).includes(alumno._id) ||
        alumno._id === excludeId
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const formData = new FormData();
    formData.append("Titulo", nombreGrupo);
    formData.append("institucion_id", $(institucionesRef.current).val());
    formData.append("departamento_id", $(departamentosRef.current).val());
    formData.append("lider_id", selectedAlumnos.lider);
    formData.append(
      "participantes",
      JSON.stringify([
        selectedAlumnos.participante1,
        selectedAlumnos.participante2,
        selectedAlumnos.participante3,
      ])
    );
    formData.append("coach_id", selectedAlumnos.coach);
    formData.append("Inscrito", true);

    const dniFile = document.getElementById("DNIFile").files[0];
    const constanciaFile = document.getElementById("ConstanciaFile").files[0];

    if (dniFile) {
      formData.append("DNIFile", dniFile);
    }

    if (constanciaFile) {
      formData.append("ConstanciaFile", constanciaFile);
    }

    try {
      const response = await axios.post(
        `${window.BACKEND_URL}alumno/inscritos`,
        formData,
        {
          headers: {
            Authorization: `${localStorage.getItem("TokenPublic")}`,
            "X-Rol": `${localStorage.getItem("Rol")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Inscripción Exitosa",
          text: "Se envió un correo de información al grupo",
          showConfirmButton: false,
        });
        window.location.reload();
      }
    } catch (error) {
      console.error("Error al enviar los datos:", error);
      alert("Error al enviar los datos");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <>
        <Navbar />
        <div className="d-flex justify-content-center align-items-center m-7">
          <div className="text-center">
            <div className="loader"></div>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Navbar />
      <section className="bg-light position-relative">
        <figure className="position-absolute bottom-0 start-0 d-none d-lg-block">
          <svg width="822.2px" height="301.9px" viewBox="0 0 822.2 301.9">
            <path
              className="fill-warning opacity-5"
              d="M752.5,51.9c-4.5,3.9-8.9,7.8-13.4,11.8c-51.5,45.3-104.8,92.2-171.7,101.4c-39.9,5.5-80.2-3.4-119.2-12.1 c-32.3-7.2-65.6-14.6-98.9-13.9c-66.5,1.3-128.9,35.2-175.7,64.6c-11.9,7.5-23.9,15.3-35.5,22.8c-40.5,26.4-82.5,53.8-128.4,70.7 c-2.1,0.8-4.2,1.5-6.2,2.2L0,301.9c3.3-1.1,6.7-2.3,10.2-3.5c46.1-17,88.1-44.4,128.7-70.9c11.6-7.6,23.6-15.4,35.4-22.8 c46.7-29.3,108.9-63.1,175.1-64.4c33.1-0.6,66.4,6.8,98.6,13.9c39.1,8.7,79.6,17.7,119.7,12.1C634.8,157,688.3,110,740,64.6 c4.5-3.9,9-7.9,13.4-11.8C773.8,35,797,16.4,822.2,1l-0.7-1C796.2,15.4,773,34,752.5,51.9z"
            ></path>
          </svg>
        </figure>{" "}
        <div className="container position-relative h-50">
          <div className="row">
            <div className="col-12">
              <div className="row align-items-center">
                <figure className="position-absolute top-0 start-0">
                  <svg width="22px" height="22px" viewBox="0 0 22 22">
                    <polygon
                      className="fill-orange"
                      points="22,8.3 13.7,8.3 13.7,0 8.3,0 8.3,8.3 0,8.3 0,13.7 8.3,13.7 8.3,22 13.7,22 13.7,13.7 22,13.7 "
                    ></polygon>
                  </svg>
                </figure>

                <div className="col-6 col-md-3 text-center order-1">
                  <img
                    src="/assets/images/stemisp/izquierda.svg"
                    alt=""
                    className="w-50"
                  />
                </div>
                <div className="col-md-6 px-md-5 text-center position-relative order-md-2 mb-5 mb-md-0">
                  <figure className="position-absolute top-0 start-50 translate-middle mt-n6 d-none d-md-block">
                    <svg width="27px" height="27px">
                      <path
                        className="fill-purple"
                        d="M13.122,5.946 L17.679,-0.001 L17.404,7.528 L24.661,5.946 L19.683,11.533 L26.244,15.056 L18.891,16.089 L21.686,23.068 L15.400,19.062 L13.122,26.232 L10.843,19.062 L4.557,23.068 L7.352,16.089 L-0.000,15.056 L6.561,11.533 L1.582,5.946 L8.839,7.528 L8.565,-0.001 L13.122,5.946 Z"
                      ></path>
                    </svg>
                  </figure>
                  <h1 className="mb-3">Inscribe a tu Equipo</h1>
                </div>
                <div className="col-6 col-md-3 text-center order-3">
                  <img
                    src="/assets/images/stemisp/derecha2.svg"
                    className="w-50"
                    alt=""
                  />
                </div>
              </div>{" "}
            </div>
          </div>{" "}
        </div>
      </section>
      <section>
        {torneo ? (
          <div className="container-fluid">
            <div className="row justify-content-center">
              {/* Columna de Selección de Participantes */}
              <div className="col-md-7 col-12 bg-opacity-10 border p-4 order-md-2 order-1 shadow rounded-4">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="mb-3">
                      <h5 className="text-azul-stemis">Nombre del grupo:</h5>
                      <input
                        type="text"
                        name="Titulo"
                        value={nombreGrupo}
                        onChange={(e) => setNombreGrupo(e.target.value)}
                        placeholder="Ingrese el nombre del grupo"
                        className="form-control"
                        required
                      />
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                      <h5 className="text-azul-stemis">Universidad:</h5>
                      <select
                        id="InstitucionesId"
                        ref={institucionesRef}
                        className="form-control border border-dark"
                        required
                      >
                        <option></option>
                        {instituciones.length > 0 ? (
                          instituciones.map((institucion) => (
                            <option key={institucion.Id} value={institucion.Id}>
                              {institucion.Nombre}
                            </option>
                          ))
                        ) : (
                          <option disabled>
                            No hay instituciones disponibles
                          </option>
                        )}
                      </select>
                    </div>

                    <div className="col-md-6 col-12 mb-3">
                      <h5 className="text-azul-stemis">Región:</h5>
                      <select
                        id="DepartamentosId"
                        ref={departamentosRef}
                        className="form-control"
                        required
                      >
                        <option></option>
                        {departamentos.length > 0 ? (
                          departamentos
                            .slice() // Crear una copia del array para no mutar el original
                            .sort((a, b) => a.Nombre.localeCompare(b.Nombre)) // Ordenar alfabéticamente por el nombre del departamento
                            .map((departamento) => (
                              <option
                                key={departamento.Id}
                                value={departamento.Id}
                              >
                                {departamento.Nombre}
                              </option>
                            ))
                        ) : (
                          <option disabled>
                            No hay departamentos disponibles
                          </option>
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="row my-4">
                    {/* Líder */}
                    <div className="col-lg-6 col-md-12 mb-4">
                      <div className="card border-0 bg-opacity-10 shadow">
                        <div className="card-body">
                          <h5 className="card-title text-azul-stemis">
                            Participante 1 (Líder)
                          </h5>
                          <select
                            required
                            id="LiderId"
                            ref={liderRef}
                            className="form-select mb-3"
                          >
                            <option></option>
                            {filteredAlumnos(selectedAlumnos.lider).map(
                              (alumno) => (
                                <option key={alumno._id} value={alumno._id}>
                                  {alumno.Nombre}- {alumno.Dni}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* Participante 2 */}
                    <div className="col-lg-6 col-md-12 mb-4">
                      <div className="card border-0 bg-opacity-10 shadow">
                        <div className="card-body">
                          <h5 className="card-title text-azul-stemis">
                            Participante 2
                          </h5>
                          <select
                            required
                            id="Participante1Id"
                            ref={participante1Ref}
                            className="form-select mb-3"
                          >
                            <option></option>
                            {filteredAlumnos(selectedAlumnos.participante1).map(
                              (alumno) => (
                                <option key={alumno._id} value={alumno._id}>
                                  {alumno.Nombre}- {alumno.Dni}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* Participante 3 */}
                    <div className="col-lg-6 col-md-12 mb-4">
                      <div className="card border-0 bg-opacity-10 shadow">
                        <div className="card-body">
                          <h5 className="card-title text-azul-stemis">
                            Participante 3
                          </h5>
                          <select
                            required
                            id="Participante2Id"
                            ref={participante2Ref}
                            className="form-select mb-3"
                          >
                            <option></option>
                            {filteredAlumnos(selectedAlumnos.participante2).map(
                              (alumno) => (
                                <option key={alumno._id} value={alumno._id}>
                                  {alumno.Nombre}- {alumno.Dni}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* Participante 4 */}
                    <div className="col-lg-6 col-md-12 mb-4">
                      <div className="card border-0 bg-opacity-10 shadow">
                        <div className="card-body">
                          <h5 className="card-title text-azul-stemis">
                            Participante 4
                          </h5>
                          <select
                            id="Participante3Id"
                            ref={participante3Ref}
                            className="form-select mb-3"
                          >
                            <option></option>
                            {filteredAlumnos(selectedAlumnos.participante3).map(
                              (alumno) => (
                                <option key={alumno._id} value={alumno._id}>
                                  {alumno.Nombre}- {alumno.Dni}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    {/* Coach */}
                    <div className="col-lg-6 col-md-12 mb-4">
                      <div className="card border-0 bg-opacity-10 shadow">
                        <div className="card-body">
                          <h5 className="card-title text-azul-stemis">
                            Coach (opcional){" "}
                          </h5>
                          <select
                            id="CoachId"
                            ref={coachRef}
                            className="form-select mb-3"
                            onChange={(e) => {
                              const coachId = e.target.value;
                              setSelectedAlumnos((prevSelected) => ({
                                ...prevSelected,
                                coach: coachId,
                              }));
                              const coach = alumnos.find(
                                (alumno) => alumno._id === coachId
                              );
                              setSelectedCoachDetails(coach);
                            }}
                          >
                            <option></option>
                            {filteredAlumnos(selectedAlumnos.coach).map(
                              (alumno) => (
                                <option key={alumno._id} value={alumno._id}>
                                  {alumno.Nombre}- {alumno.Dni}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 mb-4">
                      <div className="card border-0 bg-opacity-10 shadow">
                        <div className="card-body">
                          <div className="mb-3">
                            <h5 className="card-title text-azul-stemis">
                              DNI <span className="h6">(Participantes)</span>
                            </h5>
                            <input
                              type="file"
                              className="form-control"
                              id="DNIFile" // Cambiado de PDFile a DNIFile
                              name="DNIFile"
                              accept=".pdf"
                            />
                            <div className="form-text ">
                              Sube Dni de todos Participantes en un solo archivo
                              Pdf
                            </div>
                          </div>
                          <div className="mb-3">
                            <h5 className="card-title text-azul-stemis">
                              Constancia{" "}
                              <span className="h6">(Participantes)</span>
                            </h5>
                            <input
                              type="file"
                              className="form-control"
                              id="ConstanciaFile" // Cambiado de PDFile a ConstanciaFile
                              name="ConstanciaFile"
                              accept=".pdf"
                            />
                            <div className="form-text">
                              Sube un documento PDF que contenga la información
                              de todos los participantes,que acredite que
                              actualmente estan inscritos en la
                              Univercidad.Ejemplo:Constancia de matrícula
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-outline-success mb-0"
                    type="submit"
                    disabled={submitting} // Deshabilita el botón mientras se carga
                  >
                    {submitting ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">
                          Inscribiendo al grupo...
                        </span>
                      </>
                    ) : (
                      <>Inscribirse</>
                    )}
                  </button>
                </form>
              </div>
              <div className="col-md-4 col-12 bg-opacity-10 border p-4 order-md-1 order-2 shadow rounded-4">
                {/* Detalles del Líder */}
                {selectedAlumnos.lider && (
                  <div className="border text-dark border-dark p-3 mb-4 rounded-2 bg-light-subtle">
                    <h5 className="card-title text-azul-stemis">
                      Detalles Participante
                    </h5>
                    {selectedAlumnoDetails && (
                      <>
                        <p>
                          <strong>Nombre:</strong>{" "}
                          {selectedAlumnoDetails.Nombre}
                        </p>
                        <p>
                          <strong>DNI:</strong> {selectedAlumnoDetails.Dni}
                        </p>
                        <p>
                          <strong>Teléfono:</strong>{" "}
                          {selectedAlumnoDetails.Celular}
                        </p>
                        <p>
                          <strong>Correo Electrónico:</strong>{" "}
                          {selectedAlumnoDetails.Correo}
                        </p>
                      </>
                    )}
                  </div>
                )}
                <div className="text-center">
                  <img
                    src="/assets/images/stemisp/ST lapiz.svg"
                    alt="Cargando..."
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Torneo no encontrado.</p>
        )}
      </section>

      <Footer />
    </>
  );
};

export default ConcursoIns;
