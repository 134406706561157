import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [colaboradores, setColaboradores] = useState([]);
  const [bloglist, setBloglist] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Nuevo estado para controlar la carga

  const navigate = useNavigate();
  const fetchColaboradores = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}colaboradores`, {
        headers: {
          Authorization: `${localStorage.getItem("TokenPublic")}`,
          "Content-Type": "application/json",
          "X-Rol": `${localStorage.getItem("Rol")}`,
        },
      });
      setColaboradores(response.data);
    } catch (error) {
      console.error("Error fetching colaboradores:", error);
    }
  };

  const fetchBlogList = async () => {
    try {
      const response = await axios.get(`${window.BACKEND_URL}blogposts`, {
        headers: {
          Authorization: `${localStorage.getItem("TokenPublic")}`,
          "Content-Type": "application/json",
          "X-Rol": `${localStorage.getItem("Rol")}`, // Enviar el token en la cabecera Authorization
        },
      });
      setBloglist(response.data);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  function formatDate(dateString) {
    const options = { month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", options);
  }

  useEffect(() => {
    setIsLoading(true); // Indicar que se está cargando
    Promise.all([fetchBlogList(), fetchColaboradores()]) // Ejecutar las dos peticiones en paralelo
      .then(() => setIsLoading(false)) // Cuando ambas peticiones terminen, indicar que la carga ha terminado
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false); // En caso de error, también indicar que la carga ha terminado
      });
    window.scrollTo(0, 0);
  }, []);

  const handleSubjectClick = (materia) => {
    setSelectedSubject(materia === selectedSubject ? null : materia);
  };

  const filteredBlogList = bloglist.filter((blog) => {
    // Si no hay ninguna materia seleccionada, mostrar todos los blogs
    // De lo contrario, mostrar solo los blogs que correspondan a la materia seleccionada
    return !selectedSubject || blog.categoria === selectedSubject;
  });

  const getColorForMateria = (materia) => {
    switch (materia) {
      case "Civil":
        return "#4FB68F";
      case "Penal":
        return "#464D5B";
      case "Constitucional":
        return "#CE214E";
      case "Laboral":
        return "#009688";
      case "Procesal":
        return "#F7C002";
      default:
        return "#C0C0C0";
    }
  };

  return (
    <>
      <Navbar />
      <section className="bg-light position-relative">
        {/* Svg decoration */}
        <figure className="position-absolute bottom-0 start-0 d-none d-lg-block">
          <svg width="822.2px" height="301.9px" viewBox="0 0 822.2 301.9">
            <path
              className="fill-warning opacity-5"
              d="M752.5,51.9c-4.5,3.9-8.9,7.8-13.4,11.8c-51.5,45.3-104.8,92.2-171.7,101.4c-39.9,5.5-80.2-3.4-119.2-12.1 c-32.3-7.2-65.6-14.6-98.9-13.9c-66.5,1.3-128.9,35.2-175.7,64.6c-11.9,7.5-23.9,15.3-35.5,22.8c-40.5,26.4-82.5,53.8-128.4,70.7 c-2.1,0.8-4.2,1.5-6.2,2.2L0,301.9c3.3-1.1,6.7-2.3,10.2-3.5c46.1-17,88.1-44.4,128.7-70.9c11.6-7.6,23.6-15.4,35.4-22.8 c46.7-29.3,108.9-63.1,175.1-64.4c33.1-0.6,66.4,6.8,98.6,13.9c39.1,8.7,79.6,17.7,119.7,12.1C634.8,157,688.3,110,740,64.6 c4.5-3.9,9-7.9,13.4-11.8C773.8,35,797,16.4,822.2,1l-0.7-1C796.2,15.4,773,34,752.5,51.9z"
            ></path>
          </svg>
        </figure>{" "}
        <div className="container position-relative">
          <div className="row">
            <div className="col-12">
              <div className="row align-items-center">
                <figure className="position-absolute top-0 start-0">
                  <svg width="22px" height="22px" viewBox="0 0 22 22">
                    <polygon
                      className="fill-orange"
                      points="22,8.3 13.7,8.3 13.7,0 8.3,0 8.3,8.3 0,8.3 0,13.7 8.3,13.7 8.3,22 13.7,22 13.7,13.7 22,13.7 "
                    ></polygon>
                  </svg>
                </figure>

                <div className="col-6 col-md-3 text-center order-1">
                  <img src="assets/images/stemisp/Mesa1.svg" alt="" />
                </div>
                {/* Content */}
                <div className="col-md-6 px-md-5 text-center position-relative order-md-2 mb-5 mb-md-0">
                  <figure className="position-absolute top-0 start-50 translate-middle mt-n6 d-none d-md-block">
                    <svg width="27px" height="27px">
                      <path
                        className="fill-purple"
                        d="M13.122,5.946 L17.679,-0.001 L17.404,7.528 L24.661,5.946 L19.683,11.533 L26.244,15.056 L18.891,16.089 L21.686,23.068 L15.400,19.062 L13.122,26.232 L10.843,19.062 L4.557,23.068 L7.352,16.089 L-0.000,15.056 L6.561,11.533 L1.582,5.946 L8.839,7.528 L8.565,-0.001 L13.122,5.946 Z"
                      ></path>
                    </svg>
                  </figure>
                  {/* Title */}
                  <h1 className="mb-3">Blogs actualizados</h1>
                  <p className="mb-3">Actualizamos todos los meses.</p>
                  {/* Search */}
                </div>
                {/* Image */}
                <div className="col-6 col-md-3 text-center order-3">
                  <img src="assets/images/stemisp/Mesa2.svg" alt="" />
                </div>
              </div>{" "}
              {/* Row END */}
            </div>
          </div>{" "}
          {/* Row END */}
        </div>
      </section>

      <section className="mt-n6 mb-n6">
        <div className="container">
          <div className="row">
            <div className="row ms-3 mt-4 align-items-center">
              <div className="col ms-n5">
                <form className="badge d-flex flex-wrap">
                  {[
                    "Todos",
                    "Civil",
                    "Penal",
                    "Constitucional",
                    "Laboral",
                    "Procesal",
                  ].map((materia) => (
                    <button
                      key={materia}
                      type="button"
                      className={`badge rounded-pill m-2 bg-white ${
                        selectedSubject === materia
                          ? "bg-secondary-soft text-white"
                          : ""
                      }`}
                      style={{ borderColor: getColorForMateria(materia) }}
                      onClick={() => handleSubjectClick(materia)}
                    >
                      <span
                        className="h6 text-uppercase"
                        style={{ color: getColorForMateria(materia) }}
                      >
                        {materia}
                      </span>
                    </button>
                  ))}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center m-7">
          <div className="text-center">
            <div className="loader"></div>
          </div>
        </div>
      ) : (
        <section className="position-relative pt-0 pt-lg-5">
          <div className="container">
            <div className="row g-4">
              {filteredBlogList.map((blog, index) => (
                <div key={blog.id} className="col-sm-6 col-lg-4 col-xl-3">
                  <div
                    className="card bg-transparent"
                    onClick={() => navigate(`/Blog/${blog._id}`)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="overflow-hidden rounded-3">
                      <img
                        src={`${window.BACKEND_URL}uploads/${blog.postcard}`}
                        alt={blog.titulo}
                        className="card-img"
                      />

                      <div className="bg-overlay bg-dark opacity-4"></div>
                      <div className="card-img-overlay d-flex align-items-start p-3">
                        {/* badge */}
                        <a className={`badge ${blog.categoria}`}>
                          {blog.categoria}
                        </a>
                      </div>
                    </div>

                    {/* Card body */}
                    <div className="card-body">
                      {/* Title */}
                      <h6 className="card-title">
                        <a>{blog.titulo}</a>
                      </h6>
                      <p className="text-truncate-2">{blog.summary}</p>
                      {colaboradores
                        .filter(
                          (colaborador) =>
                            colaborador._id === blog.colaborador_id
                        )
                        .map((colaborador) => (
                          <div className="d-flex justify-content-between">
                            <h6 className="mb-0">
                              <a>
                                {colaborador.namecol.split(" ", 2).join(" ")}
                              </a>
                            </h6>
                            <span className="small">
                              {formatDate(blog.datepost)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>{" "}
            {/* Row end */}
          </div>
        </section>
      )}
      <Footer />
    </>
  );
};

export default Blogs;
