import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Contenido = () => {
  const [colaboradores, setColaboradores] = useState([]);
  const [bloglist, setBloglist] = useState([]);

  const fetchColaboradores = () => {
    axios
      .get(`${window.BACKEND_URL}colaboradores?limit=4`, {
        headers: {
          Authorization: `${localStorage.getItem("TokenPublic")}`,
          "Content-Type": "application/json",
          "X-Rol": `${localStorage.getItem("Rol")}`,
        },
      })
      .then((response) => {
        setColaboradores(response.data);
      })
      .catch((error) => {
        console.error("Error fetching colaboradores list:", error);
      });
  };

  const fetchBlogList = () => {
    axios
      .get(`${window.BACKEND_URL}blogposts`, {
        headers: {
          Authorization: `${localStorage.getItem("TokenPublic")}`,
          "Content-Type": "application/json",
          "X-Rol": `${localStorage.getItem("Rol")}`,
        },
      })
      .then((response) => {
        const sortedBloglist = response.data.sort((a, b) => {
          return new Date(b.datepost) - new Date(a.datepost);
        });
        const latestBloglist = sortedBloglist.slice(0, 4);
        setBloglist(latestBloglist);
      })
      .catch((error) => {
        console.error("Error fetching blog list:", error);
      });
  };

  useEffect(() => {
    fetchBlogList();
    fetchColaboradores();
  }, []);
  function formatDate(dateString) {
    const options = { month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("es-ES", options);
  }
  return (
    <>
      <section className="bg-light position-relative overflow-hidden">
        <div className="container position-relative">
          {/* Title */}
          <div className="row mb-4">
            <div className="col-12">
              <h3 className="fs-2 fw-bold" style={{ color: "#009688" }}>
                <span className="position-relative z-index-9">
                  Tenemos mas contenido para ti
                </span>
              </h3>
              <p className="mb-0">
                Descubre toda nuestra información en las diversas materias del
                derecho.
              </p>
            </div>
          </div>

          <div className="container">
            {/* Title (si hay algún título que deba ir aquí, puedes agregarlo) */}
            <div className="row">
              {/* Cards */}
              {bloglist.map((blog, index) => (
                <div key={blog._id} className="col-lg-3 col-md-4 col-sm-6">
                  <div className="card bg-transparent">
                    <Link to="/Blogs/">
                      <div className="position-relative">
                        <img
                          src={`${window.BACKEND_URL}uploads/${blog?.postcard}`}
                          className="card-img"
                          alt="course image"
                        />
                      </div>
                    </Link>
                    <div className="card-body text-center">
                      <h6 className="card-title">
                        <a className="text-truncate-2"> {blog.titulo}</a>
                      </h6>

                      {colaboradores
                        .filter(
                          (colaborador) =>
                            colaborador._id === blog.colaborador_id
                        )
                        .map((colaborador) => (
                          <ul className="list-inline hstack justify-content-center">
                            <li className="list-inline-item ms-2 h6 fw-light mb-0">
                              {colaborador.namecol.split(" ", 2).join(" ")}
                            </li>

                            <li className="list-inline-item me-0 small">
                              <i className="far fa-calendar-alt me-1"></i>
                              {formatDate(blog.datepost)}
                            </li>
                          </ul>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contenido;
