import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const AulaVirtual = () => {
  const alumnoId = sessionStorage.getItem("alumnoId");
  const [matriculas, setMatriculas] = useState([]);
  const [loadingMatriculas, setLoadingMatriculas] = useState(true);

  useEffect(() => {
    const fetchMatriculas = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}matricula/alumno/${alumnoId}`,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
              "Content-Type": "application/json",
              "X-Rol": `${sessionStorage.getItem("role")}`,
            },
          }
        );

        const matriculasDelAlumno = response.data.filter(
          (matricula) => matricula.alumno_id === alumnoId
        );

        const matriculasConDetalles = await Promise.all(
          matriculasDelAlumno.map(async (matricula) => {
            const cursoResponse = await axios.get(
              `${window.BACKEND_URL}curso/${matricula.curso_id}`,
              {
                headers: {
                  Authorization: `${sessionStorage.getItem("token")}`,
                  "X-Rol": `${sessionStorage.getItem("role")}`,
                },
              }
            );

            const docenteResponse = await axios.get(
              `${window.BACKEND_URL}users/${cursoResponse.data.docente_id}`,
              {
                headers: {
                  Authorization: `${sessionStorage.getItem("token")}`,
                  "X-Rol": `${sessionStorage.getItem("role")}`,
                },
              }
            );
            const nombreDelDocente = docenteResponse.data.name || "";

            return {
              ...matricula,
              curso: cursoResponse.data,
              docente: { name: nombreDelDocente },
            };
          })
        );

        setMatriculas(matriculasConDetalles);
        setLoadingMatriculas(false); // Finaliza la carga
      } catch (error) {
        console.error("Error al obtener las matrículas:", error);
        setLoadingMatriculas(false); // Manejo de error: finaliza la carga
      }
    };

    fetchMatriculas();
  }, [alumnoId]);

  const renderDificultad = (nivel) => {
    let icono;
    let texto;

    switch (nivel) {
      case 1:
        icono = "•"; // Punto para el nivel básico
        texto = "Básico";
        break;
      case 2:
      case 3:
        icono = "★"; // Estrella para niveles intermedios
        texto = "Intermedio";
        break;
      case 4:
      case 5:
        icono = "★★½"; // Estrella y media para niveles avanzados
        texto = "Avanzado";
        break;
      default:
        icono = ""; // En caso de un nivel desconocido
        texto = "Desconocido";
    }

    return (
      <>
        {icono} {texto}
      </>
    );
  };

  return (
    <>
      <Navbar />
      <section
        className="bg-blue align-items-center d-flex"
        style={{
          background:
            "url(/assets/images/stemisp/banercursos.png)  no-repeat center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-start">
              <h1 className="text-white">Cursos matriculados</h1>
              <div className="d-flex justify-content-start">
                <nav aria-label="breadcrumb"></nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="row g-4">
                {loadingMatriculas ? (
                  <div className="d-flex justify-content-center align-items-center m-7">
                    <div className="text-center">
                      <div className="loader"></div>
                    </div>
                  </div>
                ) : matriculas && matriculas.length === 0 ? (
                  <div className="col-12">
                    <p>No tiene cursos matriculados.</p>
                  </div>
                ) : (
                  matriculas.map((matricula) => (
                    <div key={matricula._id} className="col-sm-6 col-xl-4">
                      <div className="card shadow h-100">
                        <img
                          src={`${window.BACKEND_URL}uploads/${matricula.curso.pathPortad}`}
                          className="card-img-top"
                          alt="course image"
                          style={{
                            width: "300px",
                            height: "200px",
                            objectFit: "cover",
                          }}
                        />
                        <div className="card-body">
                          <div className="d-flex justify-content-between mb-2">
                            <a
                              disabled
                              className="badge bg-success bg-opacity-10 text-success"
                            >
                              {renderDificultad(matricula.curso.dificultad)}
                            </a>
                          </div>
                          <h5 className="card-title">
                            <Link to={`/AulaDetalles/${matricula.curso.slug}`}>
                              {matricula.curso.nombre}
                            </Link>
                          </h5>
                          <p className="mb-2 text-truncate-2">
                            {matricula.curso.descripcion}
                          </p>
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item me-0 small">
                              <i className="fas fa-star text-warning"></i>{" "}
                              {matricula.docente.name}
                            </li>
                          </ul>
                        </div>
                        <div className="card-footer pt-0 pb-3">
                          <hr />
                          <div className="d-flex justify-content-between">
                            <span className="h6 fw-light mb-0">
                              <i className="far fa-clock text-danger me-2"></i>{" "}
                              {matricula.curso.horas} Horas
                            </span>
                            <span className="h6 fw-light mb-0">
                              <i className="fas fa-table text-orange me-2"></i>{" "}
                              {matricula.curso.documentos
                                ? matricula.curso.documentos.length
                                : 0}{" "}
                              Docs
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AulaVirtual;
