import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { useParams } from "react-router-dom";

const NormatDetalles = () => {
  const [normativa, setNormativa] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { slug } = useParams();
  const createMarkup = () => {
    return { __html: normativa.body };
  };
  useEffect(() => {
    const fetchNormativa = async () => {
      try {
        const response = await axios.get(
          `${window.BACKEND_URL}normatividad/slug/${slug}`,
          {
            headers: {
              Authorization: `${localStorage.getItem("TokenPublic")}`,
              "Content-Type": "application/json",
              "X-Rol": `${localStorage.getItem("Rol")}`,
            },
          }
        );
        setNormativa(response.data);
        setIsLoading(false); // Cambiar el estado de carga a falso cuando se complete la solicitud
      } catch (error) {
        console.error("Error fetching normativa:", error);
        setIsLoading(false); // Cambiar el estado de carga a falso en caso de error
      }
    };

    fetchNormativa();
  }, [slug]);

  return (
    <>
      <Navbar />

      {isLoading && (
        <div className="text-center">
          <img src="/assets/images/stemisp/gatitos.gif" alt="Cargando..." />

          <p>Cargando normativa...</p>
        </div>
      )}
      {!isLoading && normativa && (
        <body
          style={{
            backgroundImage:
              'url("/assets/images/logostemis/logostemisplomo.png")',
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            position: "relative",
          }}
        >
          <div data-speed=".8" className="container-fluid ">
            <img
              src={`${window.BACKEND_URL}uploads/${normativa.foto_port}`}
              className="card-img-top rounded-3"
              alt={normativa.titulo}
            />
          </div>

          <section className="pt-8 pt-md-11">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12">
                  <h1 className="display-8 text-center">{normativa.titulo}</h1>
                </div>
              </div>
            </div>
          </section>

          {/* SECTION */}
          <section className="pt-6 pt-md-8 pb-12">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-9 col-xl-8">
                  <div className="col-12 text-center">
                    <a
                      style={{
                        backgroundColor: "#259995",
                        color: "white",
                        fontWeight: "bold",
                      }}
                      className="p-3 rounded"
                      target="_blank"
                      href={`${window.BACKEND_URL}uploads/${normativa.archivopdf}`}
                      download={`${window.BACKEND_URL}uploads/${normativa.archivopdf}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>{" "}
                      Norma completa
                    </a>
                  </div>
                  <div>
                    {/* Otro contenido aquí */}
                    <div
                      className="mb-0 pt-8"
                      style={{ color: "#464D5B", textAlign: "justify" }}
                      dangerouslySetInnerHTML={createMarkup()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </body>
      )}

      <Footer />
    </>
  );
};

export default NormatDetalles;
